import BookingsSettingsMixin from './BookingsSettingsMixin'
import SecurityMeasuresMixin from './SecurityMeasuresMixin'
import CustomizationModuleMixin from './CustomizationModuleMixin'
export default {
  name: 'ZbCustomizationComponent',
  mixins: [BookingsSettingsMixin, SecurityMeasuresMixin, CustomizationModuleMixin],
  data: () => ({
    tab: 'tab-2',
    centered: false,
    grow: false,
    vertical: false,
    right: false,
    switch1: true,
    switch2: false,
    req: [2, 4, 6, 10],
    row: null,
    dialog: false,
    list: 1,
    e1: 2
  })
}
