import { ServiceFactory } from '@/services/ServiceFactory'
const _specialConditionsService = ServiceFactory.get('SpecialConditionsService')

export default {
  name: 'SpecialConditionsComponent',
  data () {
    return {
      postData: {
        name: ''
      },
      specialConditions: [],
      suggestions: [
        'El restaurante tomará tu reserva una vez todas las personas esten presentes',
        'Los asientos no están garantizados en horas pico',
        'Las promociones del restaurant no se pueden mezclar con los descuentos de la APP Reserbar',
        'El servicio para consumidores de reserbar dura 60 minutos solamente',
        'Los descuentos solo aplican al consumo en sala, no para llevar ó delivery'
      ]
    }
  },
  mounted () {
    this.indexSpecialConditions()
  },
  methods: {
    async indexSpecialConditions () {
      try {
        const { success, specialCondition } = await _specialConditionsService.index()
        if (success) {
          this.specialConditions = specialCondition
        }
      } catch (error) {
        this.$notify({
          title: 'Error:',
          type: 'error',
          text: error.msg
        })
      }
    },
    async createSpecialConditions () {
      try {
        await _specialConditionsService.create(this.postData)
        this.indexSpecialConditions()
      } catch (error) {
        this.$notify({
          title: 'Error:',
          type: 'error',
          text: error.msg
        })
      }
    },
    async destroySpecialConditions (id) {
      try {
        await _specialConditionsService.destroy(id)
        this.indexSpecialConditions()
      } catch (error) {
        this.$notify({
          title: 'Error:',
          type: 'error',
          text: error.msg
        })
      }
    }
  }
}
