import { ServiceFactory } from '@/services/ServiceFactory'
const SettingsBookingService = ServiceFactory.get('SettingsBookingService')

export default {
  data () {
    return {
      bookingsSettings: {
        peopleInPlace: 0,
        maxPeoplePerBooking: 1,
        rangeToCancel: 1,
        intervalBetweenBooking: 1
      },
      arrayPeoplePerBooking: [
        {
          value: 1,
          text: `1 ${this.$t('customization.person')}`
        },
        {
          value: 2,
          text: `2 ${this.$t('customization.persons')}`
        },
        {
          value: 3,
          text: `3 ${this.$t('customization.persons')}`
        },
        {
          value: 4,
          text: `4 ${this.$t('customization.persons')}`
        },
        {
          value: 5,
          text: `5 ${this.$t('customization.persons')}`
        },
        {
          value: 6,
          text: `6 ${this.$t('customization.persons')}`
        },
        {
          value: 7,
          text: `7 ${this.$t('customization.persons')}`
        },
        {
          value: 8,
          text: `8 ${this.$t('customization.persons')}`
        },
        {
          value: 9,
          text: `9 ${this.$t('customization.persons')}`
        },
        {
          value: 10,
          text: `10 ${this.$t('customization.persons')}`
        }
      ],
      arrayRangeToCancel: [
        {
          value: 1,
          text: `1 ${this.$t('customization.hour')}`
        },
        {
          value: 2,
          text: `2 ${this.$t('customization.hours')}`
        },
        {
          value: 3,
          text: `3 ${this.$t('customization.hours')}`
        },
        {
          value: 4,
          text: `4 ${this.$t('customization.hours')}`
        },
        {
          value: 5,
          text: `5 ${this.$t('customization.hours')}`
        },
        {
          value: 6,
          text: `6 ${this.$t('customization.hours')}`
        }
      ],
      arrayIntervalBetweenBooking: [
        {
          value: 1,
          text: `1 ${this.$t('customization.hour')}`
        },
        {
          value: 1.25,
          text: `1 ${this.$t('customization.hour')} ${this.$t('customization.mins', { min: 15 })}`
        },
        {
          value: 1.50,
          text: `1 ${this.$t('customization.hour')} ${this.$t('customization.mins', { min: 30 })}`
        },
        {
          value: 1.75,
          text: `1 ${this.$t('customization.hour')} ${this.$t('customization.mins', { min: 45 })}`
        },
        {
          value: 2,
          text: `2 ${this.$t('customization.hours')}`
        },
        {
          value: 2.25,
          text: `2 ${this.$t('customization.hours')} ${this.$t('customization.mins', { min: 15 })}`
        },
        {
          value: 2.50,
          text: `2 ${this.$t('customization.hours')} ${this.$t('customization.mins', { min: 30 })}`
        },
        {
          value: 2.75,
          text: `2 ${this.$t('customization.hours')} ${this.$t('customization.mins', { min: 45 })}`
        },
        {
          value: 3,
          text: `3 ${this.$t('customization.hours')}`
        },
        {
          value: 3.25,
          text: `3 ${this.$t('customization.hours')} ${this.$t('customization.mins', { min: 15 })}`
        },
        {
          value: 3.50,
          text: `3 ${this.$t('customization.hours')} ${this.$t('customization.mins', { min: 30 })}`
        },
        {
          value: 3.75,
          text: `3 ${this.$t('customization.hours')} ${this.$t('customization.mins', { min: 45 })}`
        },
        {
          value: 4,
          text: `4 ${this.$t('customization.hours')}`
        }
      ]
    }
  },
  mounted () {
    this.initializeBookingSettings()
  },
  methods: {
    async initializeBookingSettings () {
      try {
        const { bookingSettings } = await SettingsBookingService.get()
        this.bookingsSettings = bookingSettings
      } catch (error) {
        this.$notify({
          title: 'Error:',
          type: 'error',
          text: error.msg
        })
      }
    },
    async updateBookingSettings () {
      try {
        const { msg, bookingSettings } = await SettingsBookingService.put({
          maxPeoplePerBooking: this.bookingsSettings.maxPeoplePerBooking,
          rangeToCancel: this.bookingsSettings.rangeToCancel,
          intervalBetweenBooking: this.bookingsSettings.intervalBetweenBooking
        })
        this.bookingsSettings = bookingSettings
        this.$notify({
          title: 'Exito:',
          type: 'success',
          text: msg
        })
      } catch (error) {
        this.$notify({
          title: 'Error:',
          type: 'error',
          text: error.msg
        })
      }
    }
  }
}
