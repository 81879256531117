import { ServiceFactory } from '@/services/ServiceFactory'
const SecurityMeasuresService = ServiceFactory.get('SecurityMeasuresService')

export default {
  data () {
    return {
      securityMeasures: []
    }
  },
  watch: {
    tab () {
      if (this.tab === 'tab-3') {
        this.initializeSecurityMeasures()
      }
    }
  },
  methods: {
    async initializeSecurityMeasures () {
      try {
        const { success, securityMeasures } = await SecurityMeasuresService.get()
        if (success) {
          this.securityMeasures = securityMeasures
        }
      } catch (error) {
        this.$notify({
          title: 'Error!!',
          type: 'error',
          text: error.msg
        })
      }
    },
    async updateSecurityMeasures (id) {
      try {
        const { success, securityMeasure } = await SecurityMeasuresService.put({ zbSecurityMeasureId: id })
        if (success) {
          this.securityMeasures = this.securityMeasures.map((v) => {
            if (v.zbSecurityMeasureId === id) {
              return securityMeasure
            } else {
              return {
                active: v.active,
                description: v.description,
                image: v.image,
                name: v.name,
                zbSecurityMeasureId: v.zbSecurityMeasureId
              }
            }
          })
          this.$notify({
            title: securityMeasure.name,
            type: 'success',
            text: 'Actualizada'
          })
        }
      } catch (error) {
        this.$notify({
          title: 'Error!!',
          type: 'error',
          text: error.msg
        })
      }
    }
  }
}
